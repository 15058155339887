import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../ui/Heading";
import { Link } from "react-router-dom/cjs/react-router-dom";

const DesignDiamondRing = () => {
  const settings = {
    customPaging: function (i) {
      return <a className="dots11">{i + 1}</a>;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="RingBuilderHome design-diamond-ring-section section-space">
      <Container fluid className="showcase-container">
        <Row className="align-items-center">
          <Col md={5} xs={12}>
            <div className="textBox">
              <Heading className="text-start p-0">
                <span>Design Your Own</span>
                <h2>Diamond Ring</h2>
                <p>
                  Design a Diamond Ring That Reflects Your Style and Personality
                </p>
                <div className="content mt-4">
                  <Link
                    to="/ringsettings"
                    className="btn outline-base-btn small mr-3"
                  >
                    Start with a setting
                  </Link>
                  <Link to="/diamonds" className="btn outline-base-btn small">
                    Start with a diamond{" "}
                  </Link>
                </div>
              </Heading>
            </div>
          </Col>
          <Col md={7} xs={12}>
            <div
              class="wistia_responsive_padding"
              style={{ padding: "56.25% 0 0 0", position: "relative" }}
            >
              <div
                class="wistia_responsive_wrapper"
                style={{
                  height: "100%",
                  left: "0",
                  position: "absolute",
                  top: "0",
                  width: "100%",
                }}
              >
                <iframe
                  src="https://fast.wistia.net/embed/iframe/1frgc1kbdw?seo=false&videoFoam=true"
                  title="592c8ccf Video"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameborder="0"
                  scrolling="no"
                  class="wistia_embed"
                  name="wistia_embed"
                  msallowfullscreen
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DesignDiamondRing;
