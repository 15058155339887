import React, { useEffect } from 'react';
import { FaCamera } from 'react-icons/fa6';

const CamwearaTryOnButton = ({ sku, productId, isProductAvailable }) => {

    useEffect(() => {

        const initializeCamweara = () => {
            if (window.loadTryOnButton) {
                window.loadTryOnButton({
                    psku: sku,
                    page: 'product',
                    company: 'Belgiumwebnet',
                    regionId: 2,
                    prependButton: { class: 'your-button-class' },
                    MBappendButton: { class: 'your-mobile-button-class' },
                    styles: {
                        tryonbutton: {},
                        tryonbuttonHover: {},
                        MBtryonbutton: {},
                    },
                    tryonButtonData: { text: '', faIcon: '' },
                });
            }
        };

        const loadScript = () => {
            const script = document.createElement('script');
            script.src = 'https://cdn.camweara.com/integrations/camweara_api.js';
            script.async = true;
            script.onload = () => initializeCamweara();
            document.body.appendChild(script);
        };

        loadScript();

        return () => {

            const existingScript = document.querySelector('script[src="https://cdn.camweara.com/integrations/camweara_api.js"]');
            if (existingScript) existingScript.remove();
        };
    }, [sku, productId, isProductAvailable]);

    return (
        <>
            <div className="your-button-class your-mobile-button-class" >Virtual Try On <FaCamera /></div>
        </>
    );
};

export default CamwearaTryOnButton;
