import React from 'react';
import PropTypes from 'prop-types';

function Engraving({ text, arc, cname }) {
  const characters = text?.split('');
  arc = characters?.length*3.3;
  const degree = arc / (characters?.length*2);
  var imin = 0;
  var mmin = 0;
  var wmin = 0;
  var dmin = 0;
  var rmin = 0;
  return (
    <h1>
      {characters?.map((char, i) => 
        <>
        <span className={cname}
          key={`heading-span-${i}`}
          style={{        
            transform: `rotate(${(degree * i - arc / 4) - imin + mmin + dmin + wmin - rmin }deg)`,
            // transform: "translateX('-13px') !important"
            transformOrigin: `0 ${cname == 'gs_great_vibes' ? '26em' : '28em'} 0px`,
            fontFamily:`${cname == 'gs_great_vibes' ?'Kaushan Script':'Montserrat'}`
          }}>
          {char}
        </span>
        <p style={{display:"none"}}>{char == "I" || char == "i" || char == "t" || char == "f" || char == "j" ||  char == "l" ? imin =  imin + 0.6 : char == "M" || char == "m" ? mmin = mmin + 0.6 : char == "D" || char == "H" ? dmin = dmin + 0.2 : char == "W" || char == "w" ? wmin = wmin + 0.6 : char == "r"? rmin = rmin + 0.2 : ""}</p>
        </>
      )}
    </h1>
  );
}

Engraving.propTypes = {
  text: PropTypes.string.isRequired,
  arc: PropTypes.number, // how curved do you want the text
  radius: PropTypes.number, // how big do you want the curve
};


export default Engraving;
