import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CustomImg from "../../../../Assets/images/yana-imgs/yana-about-banner-home.png";
import Heading from "../ui/Heading";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Je_welryspelling } from "../../../../Helpers/request";

const AboutUS = () => {
  return (
    <section className="custom-design mt-lg-2 mt-4 mt-4 pt-lg-5">
      <Container fluid className="showcase-container">
        <Row className="align-items-center">
          <Col md={6} xs={12} className="text-center">
            <Image src={CustomImg} alt="Custom Design Jewelry" className="img-fluid yana_about_img_home" />
          </Col>

          <Col md={6} xs={12}>
            <Heading>
              {/* <span className="text-uppercase">Be the Designer</span> */}
              <h2 className="text-uppercase">About Yana </h2>
              <p>
              With 14 years of experience in the jewelry industry, including experience at Brown & Co., Yana now works by appointment, drawing on her extensive network of diamond dealers, wholesalers, and jewelers. Her business is rooted in loyalty, transparency, and commitment, providing clients with personalized service and exceptional value.
              </p>
            </Heading>

            <div className="text-center">
              <Link to="/our-story" className="btn outline-base-btn large ">
              Read More
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUS;
