import {
  RiFacebookBoxFill,
  RiTwitterXFill,
  RiInstagramLine,
  RiLinkedinBoxFill,
} from "react-icons/ri";
import { Navigate, Outlet } from "react-router";
import Social1 from "../Assets/images/static/Social/1.png";
import Social2 from "../Assets/images/static/Social/2.png";
import Social3 from "../Assets/images/static/Social/3.png";
import Social7 from "../Assets/images/static/Social/7.png";
import { useSelector } from "react-redux";
//-------------------------------------------------url
export const base_url = "https://yanacojewelry.com/webapi/api";
export const img_url = "https://yanacojewelry.com";
export const helmet_url = "https://yanacojewelry.com";
export const is_paypal = true;
export const is_square = true;
export const Je_welryspelling = "Jewelry";
// ------Contact Details Start ------
export const contact_number = {
  // <===---contact Number //
  text: "+1 (404) 932-2992",
  link: `tel:+1 4049322992`,
  chat: "+14049322992",
};

export const Email_details = {
  // <===---Email_Details  //
  text: "contact@yanacojewelry.com",
  link: `mailto:contact@yanacojewelry.com`,
};

export const Email_detailsSecond = {
  // <===---Second_Email_details  //
  text: "contact@yanacojewelry.com",
  link: `mailto:contact@yanacojewelry.com`,
};

export const Address_details = {
  // <===---Address_details  //
  textLine1: "3384 Peachtree Rd NE,",
  textLine2: "Atlanta, GA 30326, USA",
  link: "https://www.google.com/maps/place/3384+Peachtree+Rd+NE+%23120,+Atlanta,+GA+30326,+USA/@33.8498206,-84.3682116,820m/data=!3m2!1e3!4b1!4m6!3m5!1s0x88f50f59cedf1069:0x7cc049c4963c73a5!8m2!3d33.8498206!4d-84.3656367!16s%2Fg%2F11qz9tg0pr?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D",
};

export const client_name = {
  // <===---client_name  //
  c_name: "Yana & Co. Jewelry",
};

export const media_details = [
  // <===---media_details  //
  {
    account_alt: "Facbook",
    account_icon: <RiFacebookBoxFill />,
    account_img: Social1,
    account_link: "https://www.facebook.com/yanacojewelry",
  },
  // {
  //   account_alt: "Twitter",
  //   account_icon: <RiTwitterXFill />,
  //   account_img: Social7,
  //   account_link: "#",
  // },
  {
    account_alt: "Instgram",
    account_icon: <RiInstagramLine />,
    account_img: Social2,
    account_link: "https://www.instagram.com/yanacojewelry/",
  },
  // {
  //   account_alt: "Linkedin",
  //   account_icon: <RiLinkedinBoxFill />,
  //   account_img: Social3,
  //   account_link: "#",
  // },
];

export const InvoiceAddress = () => {
  // <===---Invoice_Address  //
  return (
    <>
      <h4 className="mb-2">20 W 47th St, Suite 601</h4>
      <h4 className="mb-0"> New York, NY 10036</h4>
    </>
  );
};

//--------------------------------------------------Api header data
export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const onTop = () => {
  window.scrollTo(0, 0);
};

export const ProtectedRoutes = () => {
  const user = useSelector((state) => state.persistedReducer.home.userData);
  return user?.isLogin ? <Outlet /> : <Navigate to="/" />;
};
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem("bw-diamonddata"))
    ? JSON.parse(localStorage.getItem("bw-diamonddata"))
    : null;
  const settingdata = JSON.parse(localStorage.getItem("bw-settingdata"))
    ? JSON.parse(localStorage.getItem("bw-settingdata"))
    : null;
  const gemstonedata = JSON.parse(localStorage.getItem("bw-gemstonedata"))
    ? JSON.parse(localStorage.getItem("bw-gemstonedata"))
    : null;
  const fancycolordata = JSON.parse(localStorage.getItem("bw-fancycolordata"))
    ? JSON.parse(localStorage.getItem("bw-fancycolordata"))
    : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr));
    }
  }
};

//---------------------------------------------------stud builder
export const setStudSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem("bw-stud-diamonddata"))
    ? JSON.parse(localStorage.getItem("bw-stud-diamonddata"))
    : null;
  const settingdata = JSON.parse(localStorage.getItem("bw-stud-settingdata"))
    ? JSON.parse(localStorage.getItem("bw-stud-settingdata"))
    : null;
  const gemstonedata = JSON.parse(localStorage.getItem("bw-stud-gemstonedata"))
    ? JSON.parse(localStorage.getItem("bw-stud-gemstonedata"))
    : null;
  const fancycolordata = JSON.parse(
    localStorage.getItem("bw-stud-fancycolordata")
  )
    ? JSON.parse(localStorage.getItem("bw-stud-fancycolordata"))
    : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr));
    }
  }
};

//------------------- Image error------------------------

export const ErrorImg = (dia_shape, id, type) => {
  if (dia_shape == "Round") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/round.jpg";
  } else if (dia_shape == "Princess") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/princess.jpg";
  } else if (dia_shape == "Cushion") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/cushion.jpg";
  } else if (dia_shape == "Emerald") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/emerald.jpg";
  } else if (dia_shape == "Oval") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/oval.jpg";
  } else if (dia_shape == "Radiant") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/radiant.jpg";
  } else if (dia_shape == "Asscher") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/asscher.jpg";
  } else if (dia_shape == "Marquise") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/marquise.jpg";
  } else if (dia_shape == "Heart") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/heart.jpg";
  } else if (dia_shape == "Pear") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/pear.jpg";
  } else if (dia_shape == "Gemstone") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/gemstone.png";
  } else if (dia_shape == "Fancy") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/fancy_diamond.png";
  } else if (dia_shape === "none") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/image-not-found.png";
  } else {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/diamond.png";
  }

  if (type == "detail" || type == "Complete") {
    if (type == "Complete") {
      var imgsrc = document
        .getElementsByClassName("image-gallery-thumbnail-image")[1]
        .getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName(
          "image-gallery-thumbnail-image"
        )[1].src = img;
      };
    } else {
      var imgsrc = document
        .getElementsByClassName("image-gallery-thumbnail")[0]
        .getElementsByTagName("img")[0]
        .getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[
          type == "Complete" ? 1 : 0
        ].src = img;
        document
          .getElementsByClassName("image-to-magnify")[0]
          .getElementsByTagName("img")[0].src = img;
      };
      image.onload = () => {
        document
          .getElementsByClassName("image-to-magnify")[0]
          .getElementsByTagName("img")[0].src = imgsrc;
      };
    }
    // image.onload = () => {
    //   document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
    // }
  } else {
    console.log("ImageError sku: ", id);
    document.getElementById(id).src = img;
  }
};

export const formatOrAddCurrencyStrings=(currencyString1, currencyString2 = null)=> {
  // Helper function to convert currency string to integer in cents
  function toCents(currencyString) {
    return parseFloat(currencyString.replace(/[^0-9.-]+/g, '')) * 100;
}

// If only one string is passed, just format it
if (currencyString2 === null) {
    const amountInCents = toCents(currencyString1);
    return (amountInCents / 100).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

// If two strings are passed, add them
const amountInCents1 = toCents(currencyString1);
const amountInCents2 = toCents(currencyString2);
const totalInCents = amountInCents1 + amountInCents2;

// Convert back to dollars and format with commas and two decimal places
return (totalInCents / 100).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

}

export const formatOrAddNumberStrings = (numberString1, numberString2 = null) => {
  // Helper function to convert input (string or number) to a float
  function toNumber(input) {
    if (typeof input === "string") {
      return parseFloat(input.replace(/,/g, ""));
    }
    return parseFloat(input); // If it's already a number, return it as-is
  }

  // Helper function to format a number to two decimal places with commas
  function formatNumber(number) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  // If only one input is passed, format it
  if (numberString2 === null) {
    const number = toNumber(numberString1);
    return formatNumber(number);
  }

  // If two inputs are passed, add them
  const number1 = toNumber(numberString1);
  const number2 = toNumber(numberString2);
  const total = number1 + number2;

  // Format and return the result
  return formatNumber(total);
};

