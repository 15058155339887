import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Img from "../../../../Assets/images/yana-imgs/consult.webp";
import { Je_welryspelling } from "../../../../Helpers/request";

const ConsultSpecialist = () => {
  return (
    <section className="consult-specialist-section section-space pt-0">
      <Container fluid className="showcase-container">
        <div>
          <Row>
            <Col md={6}>
              <div className="inner-wrap text-center">
                <span>Feel Free To Talk With Us</span>
                <h2>Consult With A  {Je_welryspelling}  Specialist</h2>
                <p>
                  See the collections in person at either of our
                   showrooms.
                   {/* located in NYC */}
                </p>
                <Link
                  className="btn outline-base-btn large"
                  to="/make-an-appointment"
                >
                  Book an Appointment
                </Link>
              </div>
            </Col>
            <Col md={6} className="p-0">
              <Image src={Img} alt="Feel Free To Talk With Us" />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ConsultSpecialist;
