import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';

const VirtualTryOn = () => {
    const history = useHistory();

    return (
        <div className='virtual-try-on-wrapper'>
            <Container>
                <Row>
                    <Col lg={12}>
                        <h1 className='text-center my-4'><strong>Virtual Try-On</strong></h1>
                    </Col>

                    <Col lg={4} className='mb-md-0 mb-3'>
                        <div className='item-block' onClick={() => history.push('/productdetail-2/14k-white-gold-lab-grown-diamond-fashion-earrings-earrings-1529')}>
                            <Image src="https://dl2vs6wk2ewna.cloudfront.net/overnight/LGER010/LGER010.jpg" alt="" />
                            <h3>14K White Gold Journey Earring</h3>
                            <p>$1854</p>

                            <Link to="/productdetail-2/14k-white-gold-lab-grown-diamond-fashion-earrings-earrings-1529" className='btn outline-base-btn large'>Virtual Try On</Link>
                        </div>
                    </Col>

                    <Col lg={4} className='mb-md-0 mb-3'>
                        <div className='item-block' onClick={() => history.push('/productdetail-2/14k-white-gold-fashion-rings-fashion-rings-163')}>
                            <Image src="https://dl2vs6wk2ewna.cloudfront.net/overnight/85314/85314.jpg" alt="" />
                            <h3>14K White Gold Vintage Fashion Ring</h3>
                            <p>$2469</p>

                            <Link to="/productdetail-2/14k-white-gold-fashion-rings-fashion-rings-163" className='btn outline-base-btn large'>Virtual Try On</Link>
                        </div>
                    </Col>

                    <Col lg={4} className='mb-md-0 mb-3'>
                        <div className='item-block' onClick={() => history.push('/productdetail-2/14k-white-gold-round-&-pear-shape-fashion-rings-16')}>
                            <Image src="https://dl2vs6wk2ewna.cloudfront.net/overnight/85364/85364.jpg" alt="" />
                            <h3>14K White Gold Two Stone Fashion Ring</h3>
                            <p>$25623</p>

                            <Link to="/productdetail-2/14k-white-gold-round-&-pear-shape-fashion-rings-16" className='btn outline-base-btn large'>Virtual Try On</Link>
                        </div>
                    </Col>


                    {/* <h6 style={{ cursor: "pointer", marginTop: 10 }} onClick={() => {
                        history.push('/productdetail-2/14k-white-gold-lab-grown-diamond-fashion-earrings-earrings-1529')
                    }}  >Product LGER010</h6>
                    <h6 style={{ cursor: "pointer", marginTop: 10 }} onClick={() => {
                        history.push('/productdetail-2/14k-white-gold-fashion-rings-fashion-rings-163')
                    }}  >Product 85314-3/8</h6>
                    <h6 style={{ cursor: "pointer", marginTop: 10 }} onClick={() => {
                        history.push('/productdetail-2/14k-white-gold-round-&-pear-shape-fashion-rings-16')
                    }}  >Product 85364</h6> */}
                </Row>
            </Container>
        </div>
    )
}

export default VirtualTryOn