import React from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import Review from "../Assets/img/review.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { base_url, postHeader } from "../../../../Helpers/request";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Heading from "../ui/Heading";
import SlickLeft from "../Assets/img/left.png";
import SlickRight from "../Assets/img/right.png";
import { isDesktop, isMobile } from "react-device-detect";

const Customer = () => {
  const [customerData, setCustomerData] = React.useState([]);
  React.useEffect(() => {
    GetCustomerRewies();
    console.log("customerdta", customerData);
  }, []);
  const GetCustomerRewies = () => {
    axios
      .get(`${base_url}/common/site_reviews`, {
        headers: postHeader,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          setCustomerData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <Image src={SlickLeft} alt="" />,
    nextArrow: <Image src={SlickRight} alt="" />,
    responsive: [
      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="rcs_customer_say_section">
      <Container fluid className="showcase-container">
        <div className="d-flex align-items-center justify-content-between pb-3">
          <Heading className="text-left p-0">
            <h2>Happy Customers</h2>
            <p>Words of Praise</p>
          </Heading>

          {isDesktop && (
            <div className="rcs_customer_review_btn">
              <Button
                className="btn outline-base-btn large"
                onClick={() =>
                  window.open(customerData?.google_review_url, "_blank")
                }
              >
                {" "}
                Write a Review{" "}
              </Button>
            </div>
          )}
        </div>

        <Row>
          <Col lg={12} data-aos="fade-up" data-aos-duration="1400">
            <div className="rcs_customer_review_slider">
              <Slider className="rcs_customer_say_inner" {...settings2}>
                {customerData?.data?.map((customdata) => (
                  <div className="rcs_customer_review_slide_item">
                    <div className="rcs_customer_slide_title_img mb-4">
                      <div className="rcs_customer_review_slide_img">
                        <LazyLoadImage
                          src={Review}
                          alt="Google Review Image"
                          onClick={() =>
                            window.open(
                              "https://www.google.com/search?client=firefox-b-d&q=belgium+webnet#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,,",
                              "_blank"
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="rcs_customer_slide_text_content mb-4">
                      <p>
                        {customdata?.review?.slice(0, 150)}
                        {customdata?.review?.length > 150 ? "..." : ""}
                      </p>
                      <Link
                        to="#"
                        onClick={() =>
                          window.open(
                            "https://www.google.com/search?client=firefox-b-d&q=belgium+webnet#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,,",
                            "_blank"
                          )
                        }
                      >
                        {" "}
                        Read More{" "}
                      </Link>
                    </div>
                    <div className="rcs_customer_review_footer d-flex align-items-center">
                      <div className="name-charAt mr-3">
                        {customdata?.username.charAt(0)}
                      </div>
                      <div className="rcs_customer_review_slide_title">
                        <h3>{customdata?.username}</h3>
                        <Rating
                          name="half-rating-read"
                          defaultValue={5}
                          precision={customdata?.rating}
                          size="medium"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>

        {isMobile && (
          <div className="rcs_customer_review_btn text-center mt-5">
            <Button
              className="btn outline-base-btn large"
              onClick={() =>
                window.open(customerData?.google_review_url, "_blank")
              }
            >
              {" "}
              Write a Review{" "}
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

export default Customer;
