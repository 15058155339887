import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { persistor, store } from "./Store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { hydrate, render } from "react-dom";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    rootElement
  );
}

// ReactDOM.render(
//   <Provider store={store}>
//   <PersistGate loading={null} persistor={persistor}>
//   <App />
//   </PersistGate>
//   </Provider>,
//   document.getElementById('root')
// );
