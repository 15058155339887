import React from "react";
import Heading from "../ui/Heading";
import { Container } from "react-bootstrap";

const Insta = () => {
  return (
    <section className="insta-section section-space pt-0 pb-0">
      <Container fluid className="showcase-container">
        <Heading>
          <h2>Social Media</h2>
          <p>
            Follow us on Instagram{" "}
            <a
              href="https://www.instagram.com/yanacojewelry/"
              className="text-dark"
              target="_blank"
            >
              <strong>@yanacojewelry</strong>
            </a>
          </p>
        </Heading>
        <iframe src="https://instagram.demobw.live/yanacojewelry/" frameborder="0" width="100%"
        class="insta-iframe"></iframe>
      </Container>
    </section>
  );
};

export default Insta;
