import React from "react";
import Heading from "../ui/Heading";
import Slider from "react-slick";
import { Container, Image } from "react-bootstrap";
import Halo from "../Assets/img/ring-style/halo.webp";
import Solitaire from "../Assets/img/ring-style/Solitaire.webp";
import ThreeStone from "../Assets/img/ring-style/3 stone.webp";
import SingleRow from "../Assets/img/ring-style/Single Row.webp";
import MultiRow from "../Assets/img/ring-style/Multirow.webp";
import ByPass from "../Assets/img/ring-style/bypass.webp";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SlickLeft from "../Assets/img/left.png";
import SlickRight from "../Assets/img/right.png";
import { Je_welryspelling } from "../../../../Helpers/request";

const ShopRingByStyle = () => {
  let settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <Image src={SlickLeft} alt="" />,
    nextArrow: <Image src={SlickRight} alt="" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="shop-ring-by-style section-space">
      <Container fluid className="showcase-container">
        <Heading className="text-left">
          <h2>Shop Rings By Style</h2>
          <p>Explore  {Je_welryspelling}  Collections by Style and Make a Statement.</p>
        </Heading>

        <Slider {...settings2}>
          <div className="item">
            <Link to="/ringsettings/style=Halo">
              <Image src={Halo} alt="Halo" />
            </Link>
            <Link to="/ringsettings/style=Halo">Halo</Link>
          </div>
          <div className="item">
            <Link to="/ringsettings/style=Solitaire">
              <Image src={Solitaire} alt="Solitaire" />
            </Link>
            <Link to="/ringsettings/style=Solitaire">Solitaire</Link>
          </div>
          <div className="item">
            <Link to="/ringsettings/style=Three%20stone">
              <Image src={ThreeStone} alt="ThreeStone" />
            </Link>
            <Link to="/ringsettings/style=Three%20stone">Three Stone</Link>
          </div>
          <div className="item">
            <Link to="/ringsettings/style=Single Row">
              <Image src={SingleRow} alt="SingleRow" />
            </Link>
            <Link to="/ringsettings/style=Single Row">Single Row</Link>
          </div>
          <div className="item">
            <Link to="/ringsettings/style=Multirow">
              <Image src={MultiRow} alt="Multirow" />
            </Link>
            <Link to="/ringsettings/style=Multirow">Multirow</Link>
          </div>
          <div className="item">
            <Link to="/ringsettings/style=Bypass">
              <Image src={ByPass} alt="Bypass" />
            </Link>
            <Link to="/ringsettings/style=Bypass">Bypass</Link>
          </div>
          <div className="item">
            <Link to="/ringsettings/style=Solitaire">
              <Image src={Solitaire} alt="Solitaire" />
            </Link>
            <Link to="/ringsettings/style=Solitaire">Solitaire</Link>
          </div>
        </Slider>
      </Container>
    </section>
  );
};

export default ShopRingByStyle;
